import { Auth2FAErrorsCode, auth, parseServerError } from '../../../api/login'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useSetUserInfoStore,
  useUserOtpTokenStore,
  useUserResetOtpInfo,
  useUserSetForceRefreshStore,
  useUserSetLoadingStore
} from './store'
import type { AuthErrorResponse } from '../../../api/login'
import type { FormInstance } from 'antd'
import type { LoginFormValues } from '.'

export const useLoginOnFinish = (
  setFields: FormInstance<LoginFormValues>['setFields']
) => {
  const navigate = useNavigate()
  const setLoading = useUserSetLoadingStore()
  const setUserInfo = useSetUserInfoStore()
  const { otpToken, email } = useUserOtpTokenStore()
  const setForceRefresh = useUserSetForceRefreshStore()
  const resetOtpInfo = useUserResetOtpInfo()

  return useCallback(
    (values: LoginFormValues) => {
      setLoading(true)
      auth
        .login({
          email,
          otpToken,
          otpCode: values.otpCode
        })
        .then(response => {
          if (response.status === 200) {
            setUserInfo(response.data)
            navigate('/login')
            resetOtpInfo()
          }
        })
        .catch((error: AuthErrorResponse) => {
          if (
            error.response?.data?.code === Auth2FAErrorsCode.INVALID_CREDENTIALS
          ) {
            setForceRefresh(true)
            resetOtpInfo()
          }
          setFields([
            {
              name: 'otpCode',
              errors: [parseServerError(error)]
            }
          ])
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [otpToken]
  )
}

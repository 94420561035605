import Axios from 'axios'
import { baseURL } from '.'
import { useEffect } from 'react'
import { useUserTokenStore } from '../app/pages/Login/store'
import type { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'

export const AXIOS_INSTANCE = Axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json'
  }
})

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token
  }).then(({ data }) => data)

  // @ts-expect-error
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export const useRequestInterceptorsWithToken2 = () => {
  const token = useUserTokenStore()
  function onFullfilled(config: InternalAxiosRequestConfig<any>) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  }

  useEffect(() => {
    const interceptor = AXIOS_INSTANCE.interceptors.request.use(
      onFullfilled,
      null,
      { synchronous: true }
    )
    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptor)
    }
  }, [token])
}

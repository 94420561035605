import { PROMOCODES } from './promocodes'
import { apiClient } from '.'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { CompaniesWithTotal } from './types/company'
import type { UseQueryOptions } from '@tanstack/react-query'
import type { WithPaginationFilters } from './types/pagination'

const COMPANIES = 'COMPANIES'

export type CompanyGetParams = WithPaginationFilters<{
  nameContains?: string
  isActive?: boolean
}>

export type CompanyCreateBody = {
  name: string
  isActive?: boolean
}

type CompanyPatchBody = Partial<CompanyCreateBody>

const companies = {
  get: (params: CompanyGetParams) =>
    apiClient
      .get<CompaniesWithTotal>('/companies', { params })
      .then(res => res.data),
  patch: (id: string, body: CompanyPatchBody) =>
    apiClient.patch(`/companies/${id}`, body).then<string>(res => res.data),
  post: (body: CompanyCreateBody) =>
    apiClient.post('/companies', body).then<string>(res => res.data)
}

export const useCompaniesQuery = (
  params: CompanyGetParams,
  options?: UseQueryOptions<CompaniesWithTotal>
) =>
  useQuery<CompaniesWithTotal>(
    [COMPANIES, 'get', params],
    () => companies.get(params),
    options
  )

export const useCompaniesQueryRefetchOnly = (
  params: CompanyGetParams
): ReturnType<typeof useCompaniesQuery> =>
  useCompaniesQuery(params, {
    ...refetchOnlyParams,
    initialData: {
      data: [],
      total: 0
    }
  })

export const useCompanyCreateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [COMPANIES, 'create'],
    (body: CompanyCreateBody) => companies.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COMPANIES, 'get'])
      }
    }
  )
}

export const useCompanyUpdateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [COMPANIES, 'update'],
    ({ id, ...body }: CompanyPatchBody & { id: string }) =>
      companies.patch(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([COMPANIES, 'get'])
        queryClient.invalidateQueries([PROMOCODES])
      }
    }
  )
}

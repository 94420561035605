/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * YouTalk CRM API
 * OpenAPI spec version: 1.0.0
 */
import { customInstance } from '../axios'
import type {
  CertificateCreate200,
  CertificateCreateBody,
  CertificateTopup200,
  CertificateTopupBody,
  CertificateUpdate200,
  CertificateUpdateBody,
  CertificatesDownloadParams,
  CertificatesGet200,
  CertificatesGetParams
} from './index.schemas'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Retrieve certificates
 */
export const certificatesGet = (
  params?: CertificatesGetParams,
  options?: SecondParameter<typeof customInstance>
) =>
  customInstance<CertificatesGet200>(
    { url: '/certificates', method: 'GET', params },
    options
  )
/**
 * @summary Create certificate
 */
export const certificateCreate = (
  certificateCreateBody: CertificateCreateBody,
  options?: SecondParameter<typeof customInstance>
) =>
  customInstance<CertificateCreate200>(
    {
      url: '/certificates',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: certificateCreateBody
    },
    options
  )
/**
 * @summary Update certificate
 */
export const certificateUpdate = (
  id: string,
  certificateUpdateBody: CertificateUpdateBody,
  options?: SecondParameter<typeof customInstance>
) =>
  customInstance<CertificateUpdate200>(
    {
      url: `/certificates/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: certificateUpdateBody
    },
    options
  )
/**
 * @summary Download certificates
 */
export const certificatesDownload = (
  params?: CertificatesDownloadParams,
  options?: SecondParameter<typeof customInstance>
) =>
  customInstance<string>(
    { url: '/certificates/download', method: 'GET', params },
    options
  )
/**
 * @summary Create certificate
 */
export const certificateTopup = (
  certificateTopupBody: CertificateTopupBody,
  options?: SecondParameter<typeof customInstance>
) =>
  customInstance<CertificateTopup200>(
    {
      url: '/certificate/topup',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: certificateTopupBody
    },
    options
  )
export type CertificatesGetResult = NonNullable<
  Awaited<ReturnType<typeof certificatesGet>>
>
export type CertificateCreateResult = NonNullable<
  Awaited<ReturnType<typeof certificateCreate>>
>
export type CertificateUpdateResult = NonNullable<
  Awaited<ReturnType<typeof certificateUpdate>>
>
export type CertificatesDownloadResult = NonNullable<
  Awaited<ReturnType<typeof certificatesDownload>>
>
export type CertificateTopupResult = NonNullable<
  Awaited<ReturnType<typeof certificateTopup>>
>

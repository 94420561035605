/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * YouTalk CRM API
 * OpenAPI spec version: 1.0.0
 */
import { customInstance } from '../axios'
import type {
  PsychologistsGet200,
  PsychologistsGetParams
} from './index.schemas'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Retrieve psychologists
 */
export const psychologistsGet = (
  params?: PsychologistsGetParams,
  options?: SecondParameter<typeof customInstance>
) =>
  customInstance<PsychologistsGet200>(
    { url: '/psychologists', method: 'GET', params },
    options
  )
export type PsychologistsGetResult = NonNullable<
  Awaited<ReturnType<typeof psychologistsGet>>
>

import axios from 'axios'
import { useEffect } from 'react'
import { useUserTokenStore } from '../app/pages/Login/store'
import type { InternalAxiosRequestConfig } from 'axios'

const { REACT_APP_API_OVERRIDE_URL, REACT_APP_URL } = process.env

export const baseURL =
  REACT_APP_API_OVERRIDE_URL ?? `${REACT_APP_URL}/backend/api/crm`

export const apiClient = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json'
  }
})

export const useRequestInterceptorsWithToken = () => {
  const token = useUserTokenStore()
  function onFullfilled(config: InternalAxiosRequestConfig<any>) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  }

  useEffect(() => {
    const interceptor = apiClient.interceptors.request.use(onFullfilled, null, {
      synchronous: true
    })
    return () => {
      apiClient.interceptors.request.eject(interceptor)
    }
  }, [token])
}

import { omit } from 'ramda'
import type { AxiosError } from 'axios'

type ErrorConfig = { baseURL?: string; url?: string }

const withRequestUrl = (string: string, errorConfig?: ErrorConfig) => {
  const { baseURL, url } = errorConfig ?? {}
  if (typeof baseURL === 'string' && typeof url === 'string') {
    return `Request to ${baseURL}${url} failed. ${string}`
  }
  return string
}

export const parseErrorToString = (_error: unknown) => {
  const error = _error as AxiosError

  const errorString =
    typeof error.response?.data === 'object' && error.response.data !== null
      ? Object.entries(omit(['stack'], error.response.data))
          .map(([key, value]) => [`${key}:`, `"${value}"`].join(' '))
          .join(', ')
      : error.code
      ? [error.code, error.response?.data].filter(Boolean).join(' ')
      : ''

  return withRequestUrl(errorString, error.config)
}

export const parseBooleanString = (string?: string) => {
  switch (string) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return undefined
  }
}

const parseBooleanNumber = (string?: string) => {
  switch (string) {
    case '1':
      return true
    case '0':
      return false
    default:
      return undefined
  }
}

export const parseBooleanEitherString = (string?: string) =>
  parseBooleanString(string) ?? parseBooleanNumber(string)

import React, { createContext, useEffect, useRef, useState } from 'react'
import { ERR_NETWORK, auth } from '../../api/login'
import { Login } from '../pages/Login'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  Screens2FA,
  useSetJwtTokenInfoStore,
  useSetUserInfoStore,
  useUserSetLoadingStore,
  useUserSetScreenStore,
  useUserStore,
  useUserTokenStore
} from '../pages/Login/store'
import { parseErrorToString } from '../lib/parsers'
import {
  useErrorNotificationShow,
  useSuccessNotificationShow
} from '../store/notifications'

const loginPathnames = new Set(['/login', '/login/'])

const RETRY_LIMIT = 4 as const

const AuthContext = createContext({})
const useTokenRenew = () => {
  const refTimer = useRef<ReturnType<typeof setTimeout>>()
  const [retryCount, setRetryCount] = useState(0)
  const token = useUserTokenStore()
  const setJwtTokenInfo = useSetJwtTokenInfoStore()
  const setUserInfo = useSetUserInfoStore()
  const showSuccessNotification = useSuccessNotificationShow()
  const showErrorNotification = useErrorNotificationShow()
  const setLoading = useUserSetLoadingStore()
  const setScreen = useUserSetScreenStore()
  useEffect(() => {
    if (!token) {
      setLoading(false)
      return
    }
    setScreen(Screens2FA.RENEW)
    setLoading(true)
    const renewRequest = () =>
      auth
        .renewToken({ token })
        .then(response => {
          if (response.status === 200) {
            setUserInfo(response.data)
            setJwtTokenInfo(response.data)
            showSuccessNotification('Успешная авторизация по токену')
            setLoading(false)
          }
        })
        .catch(error => {
          if (error.code === ERR_NETWORK && retryCount < RETRY_LIMIT) {
            setRetryCount(prev => prev + 1)
            return
          }
          setScreen(Screens2FA.REQUEST_OTP)
          showErrorNotification(parseErrorToString(error))
          setUserInfo(null)
          setLoading(false)
        })

    if (retryCount <= RETRY_LIMIT) {
      retryCount === 0
        ? renewRequest()
        : (refTimer.current = setTimeout(() => {
            renewRequest()
          }, retryCount * 1000))
    }

    return () => refTimer.current && clearTimeout(refTimer.current)
  }, [retryCount])
}

export const AuthProvider = () => {
  const { isLoading, jwtToken } = useUserStore()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useTokenRenew()
  useEffect(() => {
    if (!isLoading && jwtToken) {
      navigate('/')
    } else {
      navigate('/login')
    }
  }, [isLoading, jwtToken])

  return (
    <AuthContext.Provider value={{}}>
      {loginPathnames.has(pathname) ? <Login /> : <Outlet />}
    </AuthContext.Provider>
  )
}

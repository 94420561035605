import { USERS } from './users'
import { apiClient } from '.'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  Currency,
  TransactionsSource,
  TransactionsType
} from './types/enums'
import type { Transaction } from './types/transaction'
import type { UseQueryOptions } from '@tanstack/react-query'

export const TRANSACTIONS = 'TRANSACTIONS'

export type TransactionGetParams = {
  clientEq?: string
  dateLte?: string
  dateGte?: string
  start?: number
  limit?: number
  sort?: string
  typeEq?: TransactionsType
  sourceEq?: TransactionsSource
  amountGte?: number
  amountLte?: number
  numberOfSessionsGte?: number
  numberOfSessionsLte?: number
}

type TransactionCreateBody = {
  amount: number
  amountPoints: number
  client: string
  promocode?: string
}

export type TransactionRefundBody = {
  transaction: string
  count: number
}

type RefundCountResponse = {
  available: number
  ratio: number
  amountCurrency?: number
  currency?: Currency
}

const transactions = {
  get: (params: TransactionGetParams) =>
    apiClient
      .get('/transaction', { params })
      .then<TransactionsPaginatedResponse>(res => res.data),
  download: (params: TransactionGetParams) =>
    apiClient
      .get('/transaction/download', { params })
      .then<string>(res => res.data),
  post: (body: TransactionCreateBody) =>
    apiClient
      .post('/transaction', body)
      .then<{ transactionId: string }>(res => res.data),
  postRefund: (body: TransactionRefundBody) =>
    apiClient.post('/transaction/refund', body),
  getRefundCount: (id: string, amountPoints?: number) =>
    apiClient
      .get(`/transaction/refund/count/${id}`, { params: { amountPoints } })
      .then<RefundCountResponse>(res => res.data)
}

type TransactionsPaginatedResponse = {
  data: Transaction[]
  total: number
}

export const useTransactionsQuery = (
  params: TransactionGetParams,
  options?: UseQueryOptions<TransactionsPaginatedResponse>
) => {
  const queryClient = useQueryClient()
  return useQuery<TransactionsPaginatedResponse>(
    [TRANSACTIONS, 'get', params],
    () => transactions.get(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USERS])
      },
      ...options
    }
  )
}

export const useTransactionsCreateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [TRANSACTIONS, 'create'],
    (body: TransactionCreateBody) => transactions.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TRANSACTIONS])
        queryClient.invalidateQueries([USERS])
      }
    }
  )
}

export const useTransactionsRefundQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [TRANSACTIONS, 'refund'],
    (params: TransactionRefundBody) => transactions.postRefund(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([TRANSACTIONS])
        queryClient.invalidateQueries([USERS])
      }
    }
  )
}

export const useTransactionsRefundCountQuery = (
  id: string,
  amountPoints?: number
) =>
  useQuery<RefundCountResponse>([TRANSACTIONS, 'getRefundCount', id], () =>
    transactions.getRefundCount(id, amountPoints)
  )

export const useTransactionsQueryRefetchOnly = (params: TransactionGetParams) =>
  useTransactionsQuery(params, refetchOnlyParams)

const useTransactionsDownloadQuery = (
  params: TransactionGetParams,
  options?: UseQueryOptions<string>
) =>
  useQuery<string>(
    [TRANSACTIONS, 'download'],
    () => transactions.download(params),

    options
  )

export const useTransactionsDownloadQueryRefetchOnly = (
  params: TransactionGetParams
): ReturnType<typeof useTransactionsDownloadQuery> =>
  useTransactionsDownloadQuery(params, {
    ...refetchOnlyParams,
    initialData: ''
  })

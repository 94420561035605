import { useCallback, useState } from 'react'

export const DEFAULT_PAGE_LIMIT = 10

interface Pagination {
  page: number
  skip: number
  limit: number
}

export function usePagination() {
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    skip: 0,
    limit: DEFAULT_PAGE_LIMIT
  })

  const handleSetPagination = useCallback((page: number) => {
    setPagination(pagination => ({
      ...pagination,
      page,
      skip: page === 1 ? 0 : pagination.limit * (page - 1)
    }))
  }, [])

  return [pagination, handleSetPagination] as const
}

/* eslint-disable @typescript-eslint/naming-convention */
// TODO: fix naming convention in the entire file
export enum SessionStatus {
  done = 'done',
  canceledByClient = 'canceled_by_client',
  lateCancellation = 'late_cancellation',
  pending = 'pending',
  booked = 'booked',
  waitingForPayment = 'waiting_for_payment',
  confirmedByPsychologist = 'confirmed_by_psychologist',
  cancelledByPsychologist = 'cancelled_by_psychologist',
  cancelledNotPaid = 'cancelled_not_paid',
  canceledByTimer = 'cancelled_by_timer'
}

export enum SessionMessenger {
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  skype = 'skype',
  zoom = 'zoom',
  viber = 'viber',
  other = 'other'
}

export enum Language {
  russian = 'russian',
  english = 'english'
}

export enum SessionType {
  individual = 'individual',
  family = 'family',
  syncChat = 'sync_chat',
  asyncChat = 'async_chat'
}

export enum TransactionsType {
  deposit = 'deposit',
  service = 'service',
  withdraw = 'withdraw'
}

export enum Currency {
  RUB = 'RUB',
  EUR = 'EUR'
}

export enum TransactionsSessionType {
  individual = 'individual',
  family = 'family'
}

export enum TransactionsStatus {
  reversed = 'reversed',
  rejected = 'rejected',
  success = 'success'
}

export enum TransactionsSource {
  certificate = 'certificate',
  coordinator = 'coordinator',
  stripe = 'stripe'
}

export enum PromocodeBusiness {
  b2b = 'b2b',
  b2c = 'b2c'
}

export enum PromocodeCurrency {
  EUR = 'EUR',
  RUB = 'RUB',
  ANY = 'ANY'
}

export enum UserType {
  b2b = 'b2b',
  other = 'other'
}

export enum PromocodeType {
  repeating = 'repeating',
  limitedSessions = 'limited_sessions',
  firstSessions = 'first_sessions'
}

export enum PromocodeRole {
  certificate = 'CERTIFICATE',
  promocode = 'PROMOCODE'
}

export enum PromocodeRoute {
  cpa_b2b = 'cpa_b2b',
  yandex_direct_brand = 'yandex_direct_brand',
  yandex_direct_nobrand = 'yandex_direct_nobrand',
  google_ads_brand = 'google_ads_brand',
  google_ads_nobrand = 'google_ads_nobrand',
  blogger = 'blogger',
  b2b = 'b2b',
  cpa = 'cpa',
  hr = 'hr',
  pr = 'pr',
  smm = 'smm',
  webinar = 'webinar',
  other = 'other',
  context = 'context',
  merch = 'merch',
  partners = 'partners',
  podcast = 'podcast',
  mailing = 'mailing',
  referral = 'referral',
  specProject = 'spec_project',
  target = 'target',
  certificate = 'certificate',
  research = 'research',
  notAPromocode = 'not_a_promocode',
  psychologist = 'psychologist',
  giftery = 'giftery',
  charity = 'charity',
  seo = 'seo',
  none = 'none',
  direct = 'direct',
  context_nobrand = 'context_nobrand',
  context_brand = 'context_brand',
  brandformance = 'brandformance',
  error = 'errors',
  crm = 'crm',
  content = 'content',
  yandex_direct_test = 'yandex_direct_test',
  app_ios = 'app_ios',
  app_android = 'app_android',
  app = 'app'
}

export enum PromocodeAllowedAmount {
  single = 'single',
  bundle = 'bundle',
  any = 'any'
}

export enum PromocodeSessionType {
  individual = 'individual',
  family = 'family'
}

export enum ArchivationReason {
  therapyEnded = 'therapy_ended',
  paused = 'paused',
  irregular = 'irregular',
  unresponsive = 'unresponsive',
  changedTherapist = 'changed_therapist'
}

export enum ImportState {
  succeeded = 'succeeded',
  pending = 'pending',
  failed = 'failed'
}

export enum ImportType {
  promocodes = 'promocodes',
  certificates = 'certificates',
  badges = 'badges',
  users = 'users',
  relationships = 'relationships',
  psychologists = 'psychologists'
}

export enum SessionRole {
  session = 'SESSION',
  break = 'BREAK'
}

export enum RequesterType {
  client = 'CLIENT',
  psychologist = 'PSYCHOLOGIST'
}

export enum PsychologistGender {
  man = 'man',
  woman = 'woman'
}

export enum EducationType {
  education = 'education',
  training = 'training'
}
export enum QuestionnaireRating {
  PERFECT = 'PERFECT',
  GOOD = 'GOOD',
  OK = 'OK',
  BAD = 'BAD',
  TERRIBLE = 'TERRIBLE'
}

export enum PsychologistContract {
  AGENT_NPD = 'AGENT_NPD',
  AGENT_IP = 'AGENT_IP',
  AGENT_NPD_AND_IP = 'AGENT_NPD_AND_IP',
  AGENT_NPD_CORRECTION = 'AGENT_NPD_CORRECTION'
}

export enum PromocodeUsageOptions {
  ONLY_FIRST = 'ONLY_FIRST',
  NOT_FIRST = 'NOT_FIRST',
  ANY = 'ANY'
}

import { DEFAULT_PAGE_LIMIT } from './usePagination'
import { useEffect } from 'react'
import type { Pagination } from '../molecules/Pagination/store'

export const usePaginationChangeEffect = (
  params: Pagination | undefined,
  refetch: () => any
) => {
  useEffect(() => {
    if (params && (params.page !== 1 || params.limit !== DEFAULT_PAGE_LIMIT)) {
      refetch()
    }
  }, [params])
}

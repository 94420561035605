import { IMPORTS } from './imports'
import { apiClient } from '.'
import { psychologistsGet } from './generated/psychologists'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  PsychologistsGet200DataItem,
  PsychologistsGetParams
} from './generated/index.schemas'
import type { PsychologistsGetResult } from './generated/psychologists'
import type { UseQueryOptions } from '@tanstack/react-query'

const PSYCHOLOGISTS = 'PSYCHOLOGISTS'

export type Psychologist = PsychologistsGet200DataItem

export const usePsychologistsQuery = (
  params: PsychologistsGetParams,
  options?: UseQueryOptions<PsychologistsGetResult>
) =>
  useQuery<PsychologistsGetResult>(
    [PSYCHOLOGISTS, 'get'],
    () => psychologistsGet(params),
    options
  )

type PsychologistsUploadQuery = { importId: string }

export const usePsychologistsUploadQuery = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [IMPORTS, PSYCHOLOGISTS],
    (stringCSV: string) =>
      apiClient
        .post<PsychologistsUploadQuery>('/psychologists/upload', stringCSV, {
          headers: { 'content-type': 'text/csv' }
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([IMPORTS, PSYCHOLOGISTS])
      }
    }
  )
}
